import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { Constants } from "@videosdk.live/react-sdk";
import React, { useState, useEffect } from "react";
import { createMeeting, getToken } from "../api";

import { meetingTypes } from "../utils/common";

export function MeetingDetailsScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  videoTrack,
  setVideoTrack,
  onClickStartMeeting,
  setMeetingMode,
  meetingMode,
  meetingType,
  setMeetingType,
}) {
  const [meetingId, setMeetingId] = useState("");
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(true);
  const [premeetingObj, setPremeetingObj] = useState({});
  const [customerName, setCustomerName] = useState("");
  const [agentName, setAgentName] = useState("");
  const [isMeetingFound, setIsMeetingFound] = useState(false);

  const queryURL = new URLSearchParams(window.location.search);
  const key = queryURL.get("key") || "";
  const agent_key = queryURL.get("agent_key") || "";
  const statusParticipant = agent_key !== "" ? `agent` : `customer`;
  let reqParam = "";

  // Save key data to sessionStorage
  sessionStorage.setItem("key", key);

  const createRoomId = async () => {
    const token = await getToken();
    const _meetingId = await createMeeting({ token });
    setMeetingId(_meetingId);
  };

  const updateRoomId = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/updatemeetingroomid?key=${key}`, {
      method: "POST",
      body: JSON.stringify({
        room_id: meetingId,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  };

  useEffect(() => {
    if (key !== "") {
      reqParam = `?key=${key}`;
    } else if (agent_key !== "") {
      reqParam = `?agent_key=${agent_key}`;
    } else {
      reqParam = null;
    }

    console.log(
      "REACT_APP_VIDEOSDK_TOKEN = ",
      process.env.REACT_APP_VIDEOSDK_TOKEN
    );
    console.log("REACT_APP_BASE_URL = ", process.env.REACT_APP_BASE_URL);

    fetch(`${process.env.REACT_APP_BASE_URL}/videomeeting/${reqParam}`)
      .then((res) => {
        if (res.status === 200) {
          setIsMeetingFound(true);
          return res.json();
        } else {
          setIsMeetingFound(false);
          return false;
        }
      })
      .then(
        (result) => {
          if (result.room_id === null) {
            createRoomId();
          }
          if (result) {
            setPremeetingObj(result);
            setMeetingId(result.room_id);
            setCustomerName(result.customer_name);
            setAgentName(result.agent_name);

            if (statusParticipant === "customer") {
              setParticipantName(result.customer_name);
            } else if (statusParticipant === "agent") {
              setParticipantName(result.agent_name);
            } else {
              setParticipantName("");
            }
          }
        }
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        // (error) => {
        //   console.log(error);
        // }
      );
  }, []);

  const selectType = [
    { label: "Meeting", value: meetingTypes.MEETING },
    { label: "Interactive Live Streaming", value: meetingTypes.ILS },
  ];

  return (
    <>
      {isMeetingFound ? (
        <div className={`flex flex-1 flex-col w-full md:p-[6px] sm:p-1 p-1.5`}>
          {iscreateMeetingClicked ? (
            <div className="border border-solid border-gray-400 rounded-xl px-4 py-3  flex items-center justify-center">
              <p className="text-white text-base">
                {meetingType === meetingTypes.MEETING
                  ? `Meeting code : ${meetingId}`
                  : `Studio code : ${meetingId}`}
              </p>
              <button
                className="ml-2"
                onClick={() => {
                  navigator.clipboard.writeText(meetingId);
                  setIsCopied(true);
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 3000);
                }}
              >
                {isCopied ? (
                  <CheckIcon className="h-5 w-5 text-green-400" />
                ) : (
                  <ClipboardIcon className="h-5 w-5 text-white" />
                )}
              </button>
            </div>
          ) : isJoinMeetingClicked ? (
            <>
              {/* <input
              defaultValue={meetingId}
              onChange={(e) => {
                setMeetingId(e.target.value);
              }}
              placeholder={
                meetingType === meetingTypes.MEETING
                  ? "Enter meeting Id"
                  : "Enter studio code"
              }
              className="px-4 py-3 bg-gray-650 rounded-xl text-white w-full text-center"
            />
            {meetingIdError && (
              <p className="text-xs text-red-600">{`Please enter valid ${
                meetingType === meetingTypes.MEETING ? "meetingId" : "studioCode"
              }`}</p>
            )} */}
            </>
          ) : null}

          {(iscreateMeetingClicked || isJoinMeetingClicked) && (
            <>
              <table className="table-fixed border-white text-white">
                <tbody>
                  <tr>
                    <td>Issue</td>
                    <td>: {premeetingObj?.meeting_name}</td>
                  </tr>
                  <tr>
                    <td>Customer</td>
                    <td>: {customerName}</td>
                  </tr>
                  <tr>
                    <td>Agent</td>
                    <td>: {agentName}</td>
                  </tr>
                </tbody>
              </table>

              <p className="text-white mt-3 text-xl text-center">
                Meeting will be recorded.
              </p>

              {/* <input
              value={participantName}
              onChange={(e) => setParticipantName(e.target.value)}
              placeholder="Enter your name"
              className="px-4 py-3 mt-5 bg-gray-650 rounded-xl text-white w-full text-center"
            /> */}

              {/* <p className="text-xs text-white mt-1 text-center">
              Your name will help everyone identify you in the meeting.
            </p> */}

              {meetingIdError && (
                <p className="text-xs text-center text-red-600 mt-3">{`Invalid ${
                  meetingType === meetingTypes.MEETING
                    ? "meetingId"
                    : "studioCode"
                }`}</p>
              )}
              <button
                disabled={
                  typeof participantName !== "undefined" &&
                  participantName.length < 3
                }
                className={`w-full ${
                  typeof participantName !== "undefined" &&
                  participantName.length < 3
                    ? "bg-gray-650"
                    : "bg-purple-350"
                }  text-white px-2 py-3 rounded-xl mt-5`}
                onClick={(e) => {
                  if (iscreateMeetingClicked) {
                    if (videoTrack) {
                      videoTrack.stop();
                      setVideoTrack(null);
                    }
                    onClickStartMeeting();
                  } else {
                    if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                      onClickJoin(meetingId);
                      if (premeetingObj.room_id === null) {
                        updateRoomId();
                      }
                    } else setMeetingIdError(true);
                  }
                }}
              >
                {meetingType === meetingTypes.MEETING
                  ? iscreateMeetingClicked
                    ? "Start a meeting"
                    : "Join a meeting"
                  : iscreateMeetingClicked
                  ? "Start a meeting"
                  : isJoinMeetingClicked &&
                    meetingMode === Constants.modes.CONFERENCE
                  ? "Join Studio"
                  : "Join Streaming Room"}
              </button>
            </>
          )}

          {!iscreateMeetingClicked && !isJoinMeetingClicked && (
            <div className="w-full md:mt-0 mt-4 flex flex-col">
              <p className="text-white text-base">Select Type</p>
              <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between w-full mb-4">
                {selectType.map((item, index) => (
                  <div
                    key={`radio_${index}`}
                    className={`flex  ${
                      index === 1
                        ? "flex-1 md:ml-2 lg:ml-0 xl:ml-2"
                        : "2xl:flex-1"
                    } items-center mb-2 md:mb-4 mt-2 lg:mb-2 xl:mb-4 bg-gray-650 rounded-lg`}
                  >
                    <input
                      id={`radio${index}`}
                      type="radio"
                      name="radio"
                      className="hidden"
                      value={item.value}
                      onChange={(e) => {
                        setMeetingType(e.target.value);
                      }}
                      checked={meetingType === item.value}
                    />
                    <label
                      htmlFor={`radio${index}`}
                      className="flex items-center cursor-pointer text-white w-full px-2 py-2 lg:w-full xl:px-2 xl:py-2"
                    >
                      <span className="w-4 h-4 inline-block mr-2 rounded-full border border-grey"></span>
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-center flex-col w-full mt-2">
                {meetingType === meetingTypes.ILS ? (
                  <>
                    <button
                      className="w-full bg-purple-350 text-white px-2 py-3 rounded-xl"
                      onClick={async (e) => {
                        const meetingId = await _handleOnCreateMeeting();
                        setMeetingId(meetingId);
                        setIscreateMeetingClicked(true);
                        if (meetingType === meetingTypes.ILS) {
                          setMeetingMode(Constants.modes.CONFERENCE);
                        }
                      }}
                    >
                      Create a meeting
                    </button>

                    <button
                      className="w-full bg-purple-350 text-white px-2 py-3 mt-5 rounded-xl"
                      onClick={async (e) => {
                        setIsJoinMeetingClicked(true);
                        if (meetingType === meetingTypes.ILS) {
                          setMeetingMode(Constants.modes.CONFERENCE);
                        }
                      }}
                    >
                      Join as a Host
                    </button>
                    <button
                      className="w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5"
                      onClick={(e) => {
                        setIsJoinMeetingClicked(true);
                        if (meetingType === meetingTypes.ILS) {
                          setMeetingMode(Constants.modes.VIEWER);
                        }
                      }}
                    >
                      Join as a Viewer
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="w-full bg-purple-350 text-white px-2 py-3 rounded-xl"
                      onClick={async (e) => {
                        const meetingId = await _handleOnCreateMeeting();
                        setMeetingId(meetingId);
                        setIscreateMeetingClicked(true);
                        if (meetingType === meetingTypes.ILS) {
                          setMeetingMode(Constants.modes.CONFERENCE);
                        }
                      }}
                    >
                      Create a meeting
                    </button>
                    <button
                      className="w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5"
                      onClick={(e) => {
                        setIsJoinMeetingClicked(true);
                        if (meetingType === meetingTypes.ILS) {
                          setMeetingMode(Constants.modes.VIEWER);
                        }
                      }}
                    >
                      Join a meeting
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p className="text-white text-xl text-center">Meeting not found</p>
          <p className="text-white text-center">
            Please check your key in the browser URL.
          </p>
        </div>
      )}
    </>
  );
}
